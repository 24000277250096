import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Circle, Icon ,Style, Fill, Stroke } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';

interface Layer {
    id: number
    feature: Array<any>
    properties: any
}

enum IconAnchorUnits {
    FRACTION =  'fraction',
    PIXELS = 'pixels'
}
// TODO: Agregar mas informacion sobre el Layer, descripcion, titulo
export default function vectorLayer (layer : Layer) {
  var features : Feature[] = []
  layer.feature.forEach((feature:any)=>{
    var feature : any = new GeoJSON({featureProjection: 'EPSG:3857', dataProjection: 'EPSG:4326'}).readFeature(feature)
    features.push(feature)
  })
  var vectorSource = new VectorSource({ features: features })
  var fill = new Fill({ color: layer.properties && layer.properties.fill && layer.properties.fill.color || 'rgba(255,0,0,.5)' })
  var stroke = new Stroke({ color: layer.properties && layer.properties.stroke && layer.properties.stroke.color || 'red' , width: layer.properties && layer.properties.stroke && layer.properties.stroke.width || 5 })
  var style = null
  if(layer.properties && layer.properties.icon){
    style = new Style({ fill, stroke, image: new Icon({
    crossOrigin: 'anonymous',
    src: layer.properties.icon.url,
    scale: layer.properties.icon.scale || 0.08,
    anchor: [layer.properties.icon.anchorX || 0.5, layer.properties.icon.anchorY || 0.5],
      anchorXUnits: layer.properties.icon.anchorXUnits == 'pixels' ? IconAnchorUnits.PIXELS : IconAnchorUnits.FRACTION ,
      anchorYUnits: layer.properties.icon.anchorYUnits == 'pixels' ? IconAnchorUnits.PIXELS : IconAnchorUnits.FRACTION
    })})
  } else {
    style = new Style({ fill, stroke, image: new Circle({radius: layer.properties && layer.properties.radius || 10, stroke, fill}) })
  }
  let vectorLayer = new VectorLayer({ source: vectorSource, style })
  if(layer.properties.popup) {vectorLayer.setProperties({popup: layer.properties && layer.properties.popup})}
  return vectorLayer
}