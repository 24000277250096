

































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorLayer from 'ol/layer/Vector'
import Collection from 'ol/Collection'
import VectorSource from 'ol/source/Vector'
import BaseLayer from 'ol/layer/Base'
import vectorLayer from './vectorLayers'
import Draw from 'ol/interaction/Draw'
import {fromLonLat} from 'ol/proj'
import colorField from '@/components/fields/color/Field.vue'
import _uniqueId from 'lodash/uniqueId'
import { Style, Fill, Stroke, Text } from 'ol/style'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import {unByKey} from 'ol/Observable'
// @ts-ignore
import ContextMenu from 'ol-contextmenu'
// @ts-ignore
import Popup from 'ol-popup'
import Point from 'ol/geom/Point'

enum GeometryType {
  POLYGON = 'Polygon',
}

interface DataSelect {
  viewPort?: any,
  features?: object[]
}

@Component({
  components: {
    colorField
  }
})
export default class MapContainer extends Vue {
  @Prop({ type: Boolean, default: false }) drawMenu ! : boolean
  @Prop({ type: Array, required: true }) layers ! : object[]  // Array of layers
  @Prop({ type: Number, default: 0 }) zoom ! : number //
  @Prop({ type: Array, default: [0, 0] }) center ! : number[]
  @Prop({ type: Object, default: {} }) initialConfig ! : any

  // Map
  myMap: Map | null = null
  // Vector Layer
  vectorLayer : VectorLayer | null = null
  // The draw interaction for the map
  drawInteraction : Draw | null = null
  // Layer for the draw in Map
  drawLayer : VectorLayer | null = null
  // Source for the draw
  sourceDraw :VectorSource | null = null
  // Feature select for click
  featurePointerSelect : Feature | null = null
  // Popup click identifier
  eventPopuClick : any
  eventMousePointer : any

  // Dialogs
  dialogColor : boolean = false
  dialogName : boolean = false

  // Values in dialog
  name : string = ''
  colorFill: string = this.initialConfig.drawConfig && this.initialConfig.drawConfig.fill && this.initialConfig.drawConfig.fill.color || '#D338386E'
  colorStroke: string = this.initialConfig.drawConfig && this.initialConfig.drawConfig.stroke && this.initialConfig.drawConfig.stroke.color || 'grey'
  widthStroke: number = this.initialConfig.drawConfig && this.initialConfig.drawConfig.stroke && this.initialConfig.drawConfig.stroke.width || 4

  tab = null
  btn_active = null

  dataSelect : DataSelect = {}
  featuresSelect : object[] = []

  @Watch('featuresSelect')
  sendDataSelect() {
    this.dataSelect.features = this.featuresSelect
    this.dataSelect.viewPort = this.myMap?.getViewport()
    this.$emit('mapSelections', this.dataSelect)
  }

  mounted () {
    this.myMap = new Map({ target: 'map-root', layers: [this.tileLayerOSM], view : this.viewLayer })
    // Add layers in map
    this.layers.forEach((layer: any) => {
      this.myMap!.addLayer(vectorLayer(layer))
    })
    this.addContextMenu()
  }

  addContextMenu() {
    const featureContextMenu : any = [
      { 
        text: 'Eliminar polígono',
        callback: (obj : any) => {
          this.sourceDraw?.removeFeature(obj.data.marker)
          this.pushFeatures(this.sourceDraw)
        }
      },
      { 
        text: 'Cambiar nombre',
        callback: (obj: any) => { 
          this.dialogName = true
          this.featurePointerSelect = obj.data.marker as Feature
        }
      }]

    var contextMenu = new ContextMenu({
      width: 150,
      defaultItems: false,
      items: []
    })

    contextMenu.on('open', (evt : any) => {
      var feature =	this.myMap?.forEachFeatureAtPixel(evt.pixel, ft => ft)
      if (feature && feature.get('featureType') === 'removable') {
        contextMenu.clear()
        featureContextMenu.find( (obj : any) => obj.text === 'Eliminar polígono').data = { marker: feature }
        featureContextMenu.find( (obj : any) => obj.text === 'Cambiar nombre').data = { marker: feature }
        contextMenu.extend(featureContextMenu)
      } else {
        contextMenu.clear()
      }
    })

    contextMenu.on('beforeopen', (evt : any) => {
      var feature =	this.myMap?.forEachFeatureAtPixel(evt.pixel, ft => ft)
      if (feature && this.btnActive == 'move') { // open only on features
        contextMenu.enable()
      } else {
        contextMenu.disable()
      }
    })

    this.myMap?.addControl(contextMenu)
  }

  addPointInteraction() {
    this.myMap?.removeInteraction(this.drawInteraction!)
    this.eventMousePointer =  this.myMap?.on('pointermove', (e) => {
      if (this.myMap?.hasFeatureAtPixel(e.pixel)) {
        this.myMap!.getViewport().style.cursor = 'pointer'
      } else {
        this.myMap!.getViewport().style.cursor = 'inherit'
      }
    })
    this.eventPopuClick = this.myMap?.on('singleclick', (e : any) => {
      let featuresSelects : Feature[] = []
      let layersSelects : VectorLayer[] = []
      this.myMap?.forEachFeatureAtPixel(e.pixel, function(feature : any, layer : any) {
        featuresSelects.push(feature)
        layersSelects.push(layer)
      })
      const featureSelect = featuresSelects[0] as Feature
      const layerSelect = layersSelects[0] as VectorLayer
      
      if(featureSelect && layerSelect && layerSelect.getProperties().popup && layerSelect.getProperties().popup.template){
        var popupOverlay = new Popup({insertFirst: false})
        this.myMap?.addOverlay(popupOverlay)
        const pointSelect = featureSelect.getGeometry() as Point
        popupOverlay.show(pointSelect.getCoordinates(), this.templateTransform(layerSelect.getProperties().popup.template, featureSelect.getProperties().moreData))
      }
    })
  }

  templateTransform(str : string, obj ?: any){
    if(!obj) {return str}
    const arregloVariables = str.match(/\{\{[^}]*\}\}/g)
    const mapVariables = arregloVariables?.reduce((o, key, i) => Object.assign(o, {[key]: obj[key.replace(/{{|}}/g, '')] }), {})
    if(!mapVariables) {return str}
    var re = new RegExp(Object.keys(mapVariables!).join("|"),"gi")
    var result = str.replace(re, function(matched : any){
      // @ts-ignore
      return mapVariables[matched.toLowerCase()]
    })
    return result
  }

  @Watch('layers')
  updateSource() {
    var layers : Collection<BaseLayer> | undefined = this.myMap?.getLayers()  // Get the map layers
    layers!.clear() // Clean layers
    this.myMap?.addLayer(this.tileLayerOSM) // Add tile layer
    this.myMap?.addLayer(this.singletonDrawLayer(this.singletonDrawSource()))
    this.layers.forEach((layer: any) => {
      this.myMap!.addLayer(vectorLayer(layer))  // Add new layers
    })
  }

  changeName() {
    var feature : any = this.sourceDraw?.getFeatureById(this.featurePointerSelect?.getId()!)
    var style : Style = feature?.getStyle()
    style.setText(new Text({text: this.name, font: '20px Aspira Wide Regular'}))
    feature?.setStyle(style)
    feature.setProperties({name: this.name})
    this.dialogName = false
    this.name = ''
    this.pushFeatures(this.sourceDraw)
  }

  cleanLayerDraw(){
    // Remove draw layer and interaction
    this.myMap?.removeLayer(this.drawLayer!)
    this.removeInteraction()
    this.drawLayer = null
    this.drawInteraction = null
    this.sourceDraw = null
    this.btnActive = 'move'
    this.pushFeatures(this.sourceDraw)
  }

  removeInteraction(){
    this.myMap?.removeInteraction(this.drawInteraction!)
    //Remove interaction popup
    unByKey(this.eventPopuClick)
    unByKey(this.eventMousePointer)
  }

  // Add interaction
  addInteraction(){
    //Remove interaction popup
    unByKey(this.eventPopuClick)
    this.myMap?.addInteraction(this.singletonInteraction(this.singletonDrawSource()))
    this.myMap?.removeLayer(this.singletonDrawLayer(this.singletonDrawSource()))
    this.myMap?.addLayer(this.singletonDrawLayer(this.singletonDrawSource()))
  }

  singletonDrawLayer(source : any){
    if(!this.drawLayer) {
      this.drawLayer = new VectorLayer({ source: source })
      return this.drawLayer
    } else {
      return this.drawLayer
    }
  }

  singletonInteraction(source : any){
    if(!this.drawInteraction){
      this.drawInteraction = new Draw({
        source: source,
        type: GeometryType.POLYGON,
      })
      this.drawInteraction.on('drawend', (evtDraw) => {
        var style = new Style({
          fill: new Fill({color: this.colorFill}),
          stroke: new Stroke({color: this.colorStroke, width: this.widthStroke})
        })
        evtDraw.feature.setProperties({featureType: 'removable', style: style})
        evtDraw.feature.setStyle(style)
        evtDraw.feature.setId(_uniqueId())

        this.pushFeatures(source, evtDraw)
      })

      return this.drawInteraction
    }
    else { 
      return this.drawInteraction
    }
  }

  pushFeatures(source : any, aditionalFeatures ?: any){
    var geoJson = new GeoJSON({featureProjection: 'EPSG:3857', dataProjection: 'EPSG:4326'})
    var featureList : object[] = []
    source?.getFeatures().forEach((f : any) => { 
      featureList.push(geoJson.writeFeatureObject((f as Feature)!))
    })
    if(aditionalFeatures) {
      featureList.push(geoJson.writeFeatureObject((aditionalFeatures.feature as Feature)))
    }
    this.featuresSelect = featureList
  }

  singletonDrawSource(){
    if(!this.sourceDraw) {
      this.sourceDraw = new VectorSource({wrapX: false})
      return this.sourceDraw
    } else {
      return this.sourceDraw
    }
  }

  set btnActive(value : any){
    this.btn_active = value
    if(this.btn_active === 'move') {this.removeInteraction()}
    if(this.btn_active === 'draw') {this.addInteraction()}
    if(this.btn_active === 'point') {this.addPointInteraction()}
  }

  get btnActive() {
    return this.btn_active
  }

  get tileLayerOSM() : TileLayer {
    return new TileLayer({ source : new OSM()})
  }

  get viewLayer() :View {
    return new View({ zoom: this.zoom, center: fromLonLat(this.center, 'EPSG:3857')})  // Initial view of map
  }

}
